<template>
	<div class="popover-menu">
		<a
			class="trigger"
			:href="triggerLink"
			@click.prevent="toggleMenu"
			@keydown.esc="toggleMenu"
		>
			<slot name="title"></slot>
			<slot name="icon"></slot>
			<slot name="trigger"></slot>
		</a>
		<Transition name="slide-fade">
			<div
				v-if="open"
				:id="id"
				class="menu"
				:class="{ dark: dark }"
				:style="style"
				@keydown.esc="toggleMenu"
			>
				<div
					class="hidden-desktop"
					style="display: flex; justify-content: flex-end; cursor: pointer"
				>
					<TnIcon
						name="close"
						@click="toggleMenu"
					/>
				</div>
				<slot
					name="menu"
					:open="open"
				></slot>
			</div>
		</Transition>
	</div>
</template>
<script>
import TabTrapping from "~/mixins/platform/TabTrapping.js";
// TODO Tabtrapping
// TODO schema updates in Gizmo

import { v4 as uuid } from "uuid";
import telenorid from "~/telenorid/telenorid.js";

const id = uuid();

export default defineNuxtComponent({
	name: "PopoverMenu",
	mixins: [TabTrapping(id)],

	props: {
		disabled: { type: Boolean, default: false },
		triggerLink: { type: String, default: "javascript:" },
		"menu-hidden": { type: Boolean, default: false },
		minWidth: { type: Number, default: 218 },
		dark: { type: Boolean, default: false },
	},

	data() {
		return {
			open: false,
			id,
		};
	},

	computed: {
		style() {
			return `min-width: ${this.minWidth}px`;
		},
	},

	watch: {
		menuHidden(oldState, newState) {
			if (this.open && !newState) this.open = false;
		},
		$route() {
			this.closeMenu();
			document.removeEventListener("click", this.closeClickOutside);
		},
	},

	methods: {
		async toggleMenu(event) {
			if (this.disabled) {
				if (this.triggerLink === "/login") await telenorid.signinRedirect();
				else this.$router.push(this.triggerLink);
				return;
			}
			if (event && event.key) {
				if (event.key === "Escape") {
					this.open = false;
					this.previouslyActiveElement.focus();
				}
			} else {
				this.open = !this.open;
			}
			this.handleTabtrapping(this.open);
			if (this.open) {
				setTimeout(() => {
					document.addEventListener("click", this.closeClickOutside);
				}, 200); // wait for animation to finish
			} else {
				document.removeEventListener("click", this.closeClickOutside);
			}
		},
		closeClickOutside(e) {
			e.stopPropagation();
			if (!this.$el.contains(e.target)) {
				this.toggleMenu();
			}
		},
		openMenu() {
			window.utag?.link({
				events: {
					scOpen: 1,
				},
			});
			this.open = true;
		},
		closeMenu() {
			this.open = false;
		},
	},

	beforeRouteLeave() {
		this.closeMenu();
	},
});
</script>
<style lang="scss" scoped>
.trigger {
	display: flex;
	align-items: center;
	color: inherit;
	text-decoration: none;

	span {
		margin-right: $spacing-s;

		@include breakpoint(mobile) {
			display: none;
		}
	}
}

.popover-menu {
	position: relative;
}

.menu {
	position: absolute;
	right: 0;
	top: 100%;
	margin-top: $spacing-m;
	background: #fff;
	border-radius: 7px;
	z-index: 100;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
	width: auto;
	padding: $spacing-l;
	max-height: 95vh;
	overflow-y: scroll;

	&.dark {
		background: $color-primary-dark;
		box-shadow: 0 0 10px 0 rgba($color-primary-dark, 0.1);
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu {
	-ms-overflow-style: none;

	/* IE and Edge */
	scrollbar-width: none;

	/* Firefox */
}

.slide-fade-enter-active {
	transition: all 0.2s ease;
}

.slide-fade-leave-active {
	transition: all 0.2s ease;
}

.slide-fade-enter-from, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
	transform: translateY(-5px);
	opacity: 0;
}

@include breakpoint(mobile) {
	.menu {
		position: fixed;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top: $spacing-s;
		width: 100vw !important;
		max-width: 100vw !important;
		top: auto;
		right: 0;
		left: 0;
		min-width: initial !important;
	}
}
</style>
